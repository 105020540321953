<template>
  <div class="row text-center pt-4 pb-2">
    <div v-for="itemPesv of items" :key="itemPesv.id" :class="itemPesv.Id == 1
      ? 'col-xl-2 col-md-2 col-sm-4  offset-sm-0'
      : 'col-xl-2 col-md-2 col-sm-4'
      ">
      <button class="btn-evaluacion btn btn-circle btn-secondary"
        :class="activePasoFase1 == itemPesv.Id ? 'active' : ''" @click="updatePaso(itemPesv.Id)"
        :id="`${itemPesv.Id == 1 ? 'seleccion' : ''}`">
        <span class=" h3 ">{{ itemPesv.Id }}</span>
      </button>
      <p class="pt-3 h4">{{ itemPesv.DescripcionFase }}</p>
    </div>
    <div class="col-xl-2 col-md-2 col-sm-4">
      <button id="files" @click="updatePaso(5)" class="btn-evaluacion btn btn-circle btn-secondary"
        :class="activePasoFase1 == 5 ? 'active' : ''">
        <span class=" h3 ">5</span>
      </button>
      <p class="pt-3 h4">Archivos PESV</p>
    </div>
    <div class="col-xl-2 col-md-2 col-sm-4">
      <button id="results" class="btn-evaluacion btn btn-circle btn-secondary"
        :class="activePasoFase1 == 6 ? 'active' : ''" @click="updatePaso(6)">
        <span class=" h3 ">6</span>
      </button>
      <p class="pt-3 h4">Resultados</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState("pesv", ["activePasoFase1"]),
  },
  methods: {
    ...mapMutations("pesv", ["setPasoFase1"]),
    updatePaso(newPaso) {
      this.setPasoFase1(newPaso);
    },
  },
};
</script>

<style scoped>
/* btns fases */
.btn-evaluacion {
  border: solid 3px #16a275;
}

.btn-evaluacion:hover {
  border-color: rgb(33, 103, 255)
}

.btn-evaluacion.active {
  border-color: #df122e;
}
</style>
