<template>
  <div class="p-4">
    <hr />
    <div class="d-flex justify-content-center py-2" style="gap: 1rem">
      <!-- list steps fases -->
      <button v-for="itemPesv of items" :key="itemPesv.id" v-b-tooltip.hover :title="itemPesv.DescripcionFase"
        class="btn-evaluacion btn btn-circle btn-secondary" :class="activePasoFase1 == itemPesv.Id ? 'active' : ''"
        @click="updatePaso(itemPesv.Id)">
        <span class="h3">{{ itemPesv.Id }}</span>
      </button>
      <button v-b-tooltip.hover title="Archivos PESV" @click="updatePaso(5)"
        class="btn-evaluacion btn btn-circle btn-secondary" :class="activePasoFase1 == 5 ? 'active' : ''">
        <span class="h3">5</span>
      </button>
      <button v-b-tooltip.hover title="Resultados" @click="updatePaso(6)"
        class="btn-evaluacion btn btn-circle btn-secondary" :class="activePasoFase1 == 6 ? 'active' : ''">
        <span class="h3">6</span>
      </button>

      <!-- save changes-->
      <vs-button v-b-tooltip.hover.left title="Guardar Evaluación" success icon @click="saveData"
        class="save-changes-button" :class="{ show: shouldShowScrollButton }">
        <i class="fas fa-circle-check"></i>
      </vs-button>
      <!-- btn scroll-top -->
      <div v-b-tooltip.hover title="Ir arriba" @click="scrollToTop()"
        class="scroll-top-button m-1 bg-dark bg-dark-hover" :class="{ show: shouldShowScrollButton }">
        <i class="fas fa-circle-arrow-up" style="font-size: 1.2rem"></i>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scrollThreshold: 700,
      showScrollButton: false,
    };
  },
  computed: {
    ...mapState("pesv", ["activePasoFase1"]),
    shouldShowScrollButton() {
      return this.showScrollButton;
    },
  },
  methods: {
    ...mapMutations("pesv", ["setPasoFase1"]),
    saveData() {
      this.$emit("saveData");
    },
    updatePaso(newPaso) {
      this.scrollToTop(200);
      this.setPasoFase1(newPaso);
    },
    scrollToTop(height = 0) {
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showScrollButton = window.scrollY > this.scrollThreshold;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
/* btns fases */
.btn-evaluacion {
  border: solid 3px #16a275;
}

.btn-evaluacion:hover {
  border-color: rgb(33, 103, 255)
}

.btn-evaluacion.active {
  border-color: #df122e;
}

.scroll-top-button,
.save-changes-button {
  width: 50px;
  height: 50px;
  position: fixed;
  left: 20px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border 0.3s ease;
  transition: opacity 0.3s ease;
  z-index: 9999;
}

.scroll-top-button:hover {
  border: 2px solid;
}

.save-changes-button {
  bottom: 20px;
}

.scroll-top-button {
  bottom: 80px;
}

.scroll-top-button.show,
.save-changes-button.show {
  opacity: 1;
  pointer-events: auto;
}
</style>
